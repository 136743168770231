<script setup>
import { ref, defineEmits, defineExpose } from 'vue'
import { useDialogPluginComponent } from 'quasar';

const token = ref(null)

const { 
  dialogRef,
  onDialogHide,
  onDialogOK,
  onDialogCancel
} = useDialogPluginComponent();

defineEmits([...useDialogPluginComponent.emits]);
defineExpose({
  dialogRef,
  onDialogHide,
  onDialogCancel,
  onDialogOK
});


</script>

<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <div class="tfa-dialog p-6">
      <div class="text-xl text-primary mb-2">Verificação em duas etapas</div>
      <p class="text-sm">Introduz o código da tua aplicação de autenticação.</p>
      <q-input mask="### ###" v-model="token" label="Token de segurança" filled dense class="mt-4">
        <template #after>
          <q-btn icon="fas fa-paper-plane" color="primary" @click="onDialogOK({ token })"/>
        </template>
      </q-input>
    </div>
  </q-dialog>
</template>

<style scoped>
.tfa-dialog {
  background-color: white;
  color: black;
}

body.body--dark .tfa-dialog {
  background-color: #333; /* Example style for dark mode */
  color: white; /* Example style for text color in dark mode */ 
}
</style>