import { defineStore } from "pinia";
import axios from "axios";
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';;
const $q = useQuasar();

export const useUserStore = defineStore("store", {
  state: () => ({
    user: undefined,
    unreadNotifications: 0,
    lastUpdateTimestamp: 0, // timestamp
    darkMode: "auto",
  }),

  actions: {
    setUser(user) {
      this.user = user;
    },

    setDarkMode(mode) {
      this.darkMode = mode;
    },

    logout() {
      this.user = undefined;
    },

    async refresh() {
      try {
        const { data } = await axios.get("/api/me");
        this.user = data;

        // Verifica se o utilizar tem 2fa desativado
        if (!this.user?.twoFactorEnabled) {
          // Envia uma notificação que deve ativar
          $q.notify({
            type: "warning",
            message: "Ative a autenticação de dois fatores para aumentar a segurança da sua conta.",
            position: "top",
            timeout: 10000,
          });
        }
      } catch (error) {
        console.log(`Unable to fetch user: ${error}`);
      }

      await this.fetchNotificationsCount();
      setInterval(this.fetchNotificationsCount, 1000 * 60 * 5);
    },

    async fetchNotificationsCount() {
      // Check if user is logged in
      if (!this.user) return;

      // Request notifications
      let data;
      try {
        data = (await axios.get("/api/user/notifications")).data;
        this.unreadNotifications = data.tickets || 0;
        this.lastUpdateTimestamp = Date.now();

        // Change favicon if notifications are unread
        navigator?.setAppBadge?.(this.unreadNotifications);
        if (this.unreadNotifications) {
          document.querySelector('link[rel="icon"]').href =
            "/favicon-alert.png";
        } else {
          document.querySelector('link[rel="icon"]').href = "/favicon.png";
        }
      } catch (error) {
        data = error?.response?.data;
        console.log(`Unable to fetch notifications: ${data?.message}`);
      }
    },
  },

  persist: true,
});
