import script from "./InactivityLock.vue?vue&type=script&setup=true&lang=js"
export * from "./InactivityLock.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QIcon,QBtn});
