<script setup>
import { useUserStore } from '@/stores/user';
import { usePageLockStore } from '@/stores/pageLock';
import { ref, useTemplateRef, watch } from 'vue';
import { storeToRefs } from 'pinia';
import logo from "@/assets/infoservice-w.png";

const INACTIVITY_LOCK_TIMEOUT = 15 * 60 * 1000; // 15 minutes

const channel = new BroadcastChannel('inactivity-lock-channel');
channel.onmessage = (event) => {
  if (event.data.action === 'unlock') {
    unlockByCM(); //
  } else if (event.data.action === 'lock') {
    lockByCM();
  } else if (event.data.action === 'updateActivity') {
    updateActivity(true);
  }
}

const updateActivity = (fromMessage) => {
  localStorage.setItem('lastActivity', Date.now());
  if (!fromMessage) channel.postMessage({ action: 'updateActivity' });
}

const checkInactivity = () => {
  const lastActivity = localStorage.getItem('lastActivity');
  if (!user.lockPinEnabled) return;
  if (lastActivity && (Date.now() - lastActivity) > INACTIVITY_LOCK_TIMEOUT) {
    lock();
  }
}




const { user } = useUserStore();
const { unlock: unlockPage, lock, unlockByCM, lockByCM } = usePageLockStore();
const { locked: isLocked } = storeToRefs(usePageLockStore());
const wrongPin = ref(false);
const lockScreen = useTemplateRef('lockScreen');

// Move o ecra de bloqueio para a posição correta e desativa o scroll
// quando o ecra de bloqueio é ativado
watch(lockScreen, (val) => { 
  if (val) {
    document.body.style.overflow = 'hidden';
    lockScreen.value.style.top = `${document.documentElement.scrollTop}px`;
    wrongPin.value = false;
    pin.value = '';
  } else document.body.style.overflow = 'auto';
})

const pin = ref('');

watch(pin, (val) => {
  if (val.length === 4) {
    unlock();
  }
})

//let inactiveTimer;
const resetLockTimer = () => {
  updateActivity();
}


// Verifica se o utilizar está inativo a cada 1 minuto
setInterval(checkInactivity, 5*  1000)

window.onload = resetLockTimer;
document.onmousemove = resetLockTimer;
document.ontouchstart = resetLockTimer;

document.onkeydown = (e) => {
  resetLockTimer();
  
  if (isLocked.value) {
    if (e.key === 'Enter') unlock()
    if (e.key === 'Delete') pin.value = '';

    if (e.key === 'Backspace') pin.value = pin.value.slice(0, -1);
    if (e.key.length === 1 && pin.value.length < 4) {
      pin.value += e.key;

      /*if (pin.value.length === 4) {
        unlock();
      }*/
    }
  }
};

const unlock = async () => {
  wrongPin.value = false;
  let unlocked = await unlockPage(pin.value);
  if (!unlocked) {
    wrongPin.value = true;

    if ('vibrate' in navigator) {
      navigator.vibrate(100);
    }
  }
  pin.value = '';
}

const add = d => {
  pin.value += d;

  if ('vibrate' in navigator) {
    navigator.vibrate(50);
  }
}

checkInactivity();
</script>

<template>
  <div class="bg-gray-800 absolute left-0 h-screen w-screen z-[8900]"
    ref="lockScreen"
    v-if="isLocked"
  >
    <div class="flex items-center justify-center md:h-full">
      <div class="flex flex-col">
        <div class="text-center">
          <img :src="logo" alt="Logo" class="mt-10 sm:mt-0 w-64 mx-auto mb-4" />

          <q-icon name="fas fa-lock" size="50px" class="text-primary my-4" />
          <div class="text-white my-3">
            <p>Esta sessão encontra-se bloqueada.</p>
            <p>Insira o PIN para desbloquear.</p>
          </div>
        </div>

        <!-- Mostrador do pin -->
        <div class="flex justify-between my-4 mx-16">
          <q-icon name="fas fa-circle" size="10px" :class="{'text-red-500': pin.length}" />
          <q-icon name="fas fa-circle" size="10px" :class="{'text-red-500': pin.length > 1}" />
          <q-icon name="fas fa-circle" size="10px" :class="{'text-red-500': pin.length > 2}" />
          <q-icon name="fas fa-circle" size="10px" :class="{'text-red-500': pin.length > 3}" />
        </div>

        <!-- Mensagem de erro -->
        <div class="text-center text-red min-h-[25px]">
          <div v-if="wrongPin" class="animate-wiggle">
            <q-icon name="fas fa-times" size="20px" class="text-red-500" />
            Pin incorreto!
          </div>
        </div>

        <!-- Keyboard -->
        <div class="grid grid-cols-3 gap-2 mt-4">
          <q-btn @click="add('1')" class="bg-white text-black" label="1" />
          <q-btn @click="add('2')" class="bg-white text-black" label="2" />
          <q-btn @click="add('3')" class="bg-white text-black" label="3" />
          <q-btn @click="add('4')" class="bg-white text-black" label="4" />
          <q-btn @click="add('5')" class="bg-white text-black" label="5" />
          <q-btn @click="add('6')" class="bg-white text-black" label="6" />
          <q-btn @click="add('7')" class="bg-white text-black" label="7" />
          <q-btn @click="add('8')" class="bg-white text-black" label="8" />
          <q-btn @click="add('9')" class="bg-white text-black" label="9" />
          <q-btn @click="pin = ''" class="bg-white text-black">
            <q-icon name="fas fa-eraser" size="20px" />
          </q-btn>
          <q-btn @click="pin += '0'" class="bg-white text-black" label="0" />
          <q-btn @click="pin = pin.slice(0, -1)" class="bg-white text-black">
            <q-icon name="fas fa-backspace" size="20px" />
          </q-btn>
        </div>

        <!--q-btn @click="unlock" class="mt-2" label="Desbloquear" color="primary" /-->
      </div>
    </div>
  </div>
</template>