<script setup>
import { ref, computed, defineEmits, defineExpose } from 'vue'
import { useDialogPluginComponent } from 'quasar';
import { useUserStore } from '@/stores/user';
import { usePageLockStore } from '@/stores/pageLock';

const currentPin = ref('')
const pin1 = ref('')
const pin2 = ref('')

const userStore = useUserStore()
const user = computed(() => userStore.user);

const pageLockStore = usePageLockStore()

const { 
  dialogRef,
  onDialogHide,
  onDialogOK,
  onDialogCancel
} = useDialogPluginComponent();

defineEmits([...useDialogPluginComponent.emits]);
defineExpose({
  dialogRef,
  onDialogHide,
  onDialogCancel,
  onDialogOK
});

const confirmPin = async () => {
  if (pin1.value !== pin2.value) {
    // Show error message
    return;
  }

  await pageLockStore.setLockPin({
    pin: pin1.value,
    currentPin: currentPin.value
  }).then(() => {
    // Show success message
    userStore.refresh();
    onDialogOK();
  }).catch((error) => {
    console.error(error)
    // Show error message
  })
}

const removePin = async () => {
  try {
    await pageLockStore.removeLockPin(currentPin.value);
    userStore.refresh();
    onDialogOK();
  } catch (error) {
    console.error(error)
  }
}

</script>

<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <div class="tfa-dialog p-6" v-if="!user?.lockPinEnabled">
      <div class="text-xl text-primary mb-2">Configurar PIN</div>
      <p class="text-sm">Introduz um pin de segurança para poderes desbloquear a página.</p>
      <q-input mask="####" v-model="pin1" label="PIN" filled dense class="mt-4"
        type="password"
        :rules="[
          val => val.length === 4 || 'O PIN deve ter 4 dígitos',
        ]"
      />
      <q-input mask="####" v-model="pin2" label="Confirmação PIN" filled dense class="mt-4"
        type="password"
        :rules="[
          val => val == pin1 || 'Os PINs não coincidem',
        ]"
      />
      <div class="flex">
        <q-btn @click="confirmPin" label="Confirmar" color="primary" class="mt-2" />
      </div>
    </div>
    <div class="tfa-dialog p-6" v-else>
      <div>
        <!-- Alterar pin -->
        <div class="text-xl text-primary mb-2">Alterar PIN</div>
        <p class="text-sm">Introduz o pin original:</p>
        <q-input mask="####" v-model="currentPin" label="PIN" filled dense class="mt-4"
          type="password"
          :rules="[
            val => val.length === 4 || 'O PIN deve ter 4 dígitos',
          ]"
        />

        <q-input mask="####" v-model="pin1" label="Novo PIN" filled dense class="mt-1"
          type="password"
          :rules="[
            val => val.length === 4 || 'O PIN deve ter 4 dígitos',
          ]"
        />

        <q-input mask="####" v-model="pin2" label="Confirmação PIN" filled dense class="mt-1"
          type="password"
          :rules="[
            val => val == pin1 || 'Os PINs não coincidem',
          ]"
        />
        <div class="flex">
          <q-btn @click="confirmPin" label="Alterar PIN" color="primary" class="mt-2" />
          <q-btn @click="removePin" label="Remover PIN" color="negative" class="mt-2 ml-2" />
        </div>
      </div>
    </div>
  </q-dialog>
</template>

<style scoped>
.tfa-dialog {
  background-color: white;
  color: black;
}

body.body--dark .tfa-dialog {
  background-color: #333; /* Example style for dark mode */
  color: white; /* Example style for text color in dark mode */ 
}
</style>