import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from 'axios'
import { useUserStore } from './user'

/*
 * Sistema para bloquear a página, para evitar o acesso não autorizado quando
 * o utilizador sai do computador ou não está a usar a aplicação durante algum tempo.
 * 
 * Não é um sistema de segurança seguro, é só uma forma de evitar que outras pessoas
 * acessem a aplicação sem autorização.
 */

export const usePageLockStore = defineStore('pageLock', () => {
  const locked = ref(localStorage.getItem('pageLocked') === 'true');

  const user = useUserStore()
  const channel = new BroadcastChannel('inactivity-lock-channel');

  const lock = () => {
    locked.value = true;
    localStorage.setItem('pageLocked', 'true');
    channel.postMessage({ action: 'lock' });
  };

  const unlock = async (pin) => {
    try {
      await axios.post('/api/user/unlock', { pin });
      locked.value = false;
      localStorage.removeItem('pageLocked');
      channel.postMessage({ action: 'unlock' });
      return true;
    } catch (error) {
      console.error('Error unlocking page:', error);
      locked.value = true;
      return false;
    }
  };

  const setLockPin = async ({ pin, currentPin }) => {
    try {
      await axios.post('/api/user/setLockPin', { pin, currentPin });
      user.refresh();
      return true;
    } catch (error) {
      console.error('Error setting lock pin:', error);
      return false;
    }
  }

  // Remove lock pin from user
  const removeLockPin = async (pin) => {

    try {
      await axios.post('/api/user/removeLockPin', { pin });
      user.refresh();
      return true;
    } catch (error) {
      console.error('Error removing lock pin:', error);
      return false;
    }
  }

  const unlockByCM = () => {
    locked.value = false;
  }

  const lockByCM = () => {
    if (locked.value) return;
    lock();
  }

  return { locked, lock, unlock, setLockPin, removeLockPin, unlockByCM, lockByCM };
})